<template>
<!-- 步骤条 -->
<el-steps :active="active" finish-status="success" class="steps-business-expansion">
    <el-step>
        <template slot="title">
            <div @click="clickStep(0)" class="ui-cursor--pointer">
            用户申请
            <i class="el-icon-d-arrow-right"></i>
            <i class="el-icon-d-arrow-right"></i>
            <i class="el-icon-d-arrow-right"></i>
            </div>
        </template>
    </el-step>
    <el-step>
        <template slot="title">
            <div @click="clickStep(1)" class="ui-cursor--pointer">
            勘测阶段
            <i class="el-icon-d-arrow-right"></i>
            <i class="el-icon-d-arrow-right"></i>
            <i class="el-icon-d-arrow-right"></i>
            </div>
        </template>
    </el-step>
    <el-step>
        <template slot="title">
            <div @click="clickStep(2)" class="ui-cursor--pointer">
            供电方案
            <i class="el-icon-d-arrow-right"></i>
            <i class="el-icon-d-arrow-right"></i>
            <i class="el-icon-d-arrow-right"></i>
            </div>
        </template>
    </el-step>
    <el-step>
        <template slot="title">
            <div @click="clickStep(3)" class="ui-cursor--pointer">
            验收阶段
            </div>
        </template>
    </el-step>
</el-steps>
</template>

<script>
export default {
    props: {
        active: {
            type: Number,
            default: 0
        },
        forward: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {};
    },
    created() {

    },
    methods: {
        clickStep(index) {
            if (index < this.active || this.forward) {
                this.$emit('clickStep', index);
            }
        }
    }
};
</script>

<style lang="less" scoped>
.steps-business-expansion{
    margin: 20px;
}
</style>
