<template>
    <div class="ui-layout-container ui-width-100 workflow-list__wrap">
        <div class="workflow-list" v-if="flowPanel">
            <div class="workflow-list__header">
                <div class="workflow-list__navbar">
                    <el-menu :default-active="type" class="el-menu-bm" mode="horizontal" @select="handleSelect">
                        <el-menu-item index="all">所有业务</el-menu-item>
                        <el-menu-item index="selfStart">我发起的</el-menu-item>
                        <el-menu-item index="pending">待我处理 </el-menu-item>
                        <el-menu-item index="processed">已处理的</el-menu-item>
                    </el-menu>
                </div>
                <el-row :gutter="24" class="workflow-list__nav-form" style="margin: 6px 0px 0px 0px">
                    <el-col :span="4">
                        <div class="">
                            <el-select @change="state" v-model="code" :clearable="true" placeholder="请选择类型">
                                <el-option
                                    v-for="item in instanceList"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="">
                            <el-input
                                v-model="dataForm.extraProp.code"
                                placeholder="请输入工作单号"
                                clearable
                            ></el-input>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div>
                            <el-button @click="search" type="primary" icon="el-icon-search" style="border-radius: 13px">
                                搜索
                            </el-button>
                            <el-button
                                @click="refresh"
                                type="primary"
                                icon="el-icon-refresh"
                                style="border-radius: 13px"
                                >重置
                            </el-button>
                        </div>
                    </el-col>
                    <el-col v-if="canCreate" :span="8" style="text-align: right">
                        <el-dropdown @command="handleCommand" icon="el-icon-refresh">
                            <el-button type="primary" icon="el-icon-plus" style="margin-left:10px;">
                                新建<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <template>
                                    <el-dropdown-item
                                        v-for="(itembtn, index) in instanceList"
                                        type="primary"
                                        plain
                                        :key="index"
                                        :command="itembtn"
                                        >{{ itembtn.name }}
                                    </el-dropdown-item>
                                </template>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-col>
                </el-row>
            </div>
            <div class="workflow-list__content">
                <!-- 自定义卡片示例1 - 业务管理 -->
                <div v-if="dataList.length">
                    <template v-for="item in dataList">
                        <el-card :key="item.instanceId" class="workflow-list__item-card">
                            <div slot="header" class="workflow-list__card-head-border">
                                <span
                                    >工作单号: {{ item.data.code }}
                                    <span class="workflow-list__card-head-type">{{
                                        item.data.definitionName
                                    }}</span></span
                                >
                                <span style="float: right">{{ item.data.currentStatusName }}</span>
                            </div>
                            <el-form :inline="true" class="my-form">
                                <!--不同流程会有不同的信息展示-->
                                <!--end-->
                                <el-row style="color: #999999">
                                    <el-col :span="4"> 名称：{{ item.data.name }} </el-col>
                                    <el-col :span="6"> 当前环节：{{ item.nodeName || "已办理" }}</el-col>
                                    <el-col :span="4"> 发起人：{{ item.data.workflowCreateBy }} </el-col>
                                    <el-col :span="6"> 发起时间：{{ item.data.workflowCreateTime }} </el-col>
                                    <el-col :span="4">
                                        <div v-if="type == 'selfStart' || type == 'all' || type == 'processed'">
                                            <el-button
                                                type="info"
                                                size="small"
                                                @click="openInfo(item)"
                                                class="workflow-list__action-btn"
                                                >详情
                                            </el-button>
                                        </div>
                                        <div v-if="type == 'selfStart' ">
                                            <el-button
                                                type="info"
                                                size="small"
                                                @click="deleteFlow(item.id)"
                                                class="workflow-list__action-btn"
                                                >删除
                                            </el-button>
                                            <el-button
                                                type="info"
                                                size="small"
                                                @click="editFlow(item)"
                                                class="workflow-list__action-btn"
                                                >编辑
                                            </el-button>
                                        </div>
                                        <div v-else-if="type == 'pending'">
                                            <el-button
                                                type="info"
                                                size="small"
                                                @click="handelFlow(item)"
                                                class="workflow-list__action-btn"
                                                >处理
                                            </el-button>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-form>
                        </el-card>
                    </template>
                </div>
                <empty v-else></empty>
            </div>
            <div class="workflow-list__foot">
                <el-row :gutter="24">
                    <el-col :span="8" :offset="8">
                        <el-pagination
                            @current-change="handleCurrentChange"
                            :current-page="pageData.pageNumber"
                            :page-size="pageData.pageSize"
                            background
                            layout="total, prev, pager, next, jumper"
                            :total="total"
                        ></el-pagination>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="load-workflow-step" v-else>
            <template v-if="showStepInfo">
                <reconnaissance-show
                    v-if="showStepIndex === 1"
                    :id="currentRow.businessFormId"
                    @closePanel="closePanel"
                ></reconnaissance-show>
                <electric-plan-show
                    v-if="showStepIndex === 2"
                    :id="currentRow.businessFormId"
                    @closePanel="closePanel"
                ></electric-plan-show>
            </template>
            <div v-show="!showStepInfo">
                <handle v-if="showHandel" ref="handel_win" @closeForm="closeForm" @clickStep="clickStep"></handle>
                <info v-if="showInfo" ref="info_win" @closeForm="closeForm" @clickStep="clickStep"></info>
            </div>
        </div>
    </div>
</template>

<script>
import Handle from './workTicketHandle';
import Info from './workTicketInfo';
import ReconnaissanceShow from '../../../reconnaissance/reconnaissanceShow';
import ElectricPlanShow from '../../../electricPlan/electricPlanShow';

export default {
    name: 'WorkTicketList',
    components: {
        Handle,
        Info,
        ReconnaissanceShow,
        ElectricPlanShow
    },
    data() {
        return {
            code: '',
            flowPanel: true,
            instanceList: [],
            dataList: [],
            type: 'pending',
            showHandel: false,
            showInfo: false,
            total: 0,
            pageData: {
                pageNumber: 1,
                pageSize: 5
            },
            dataForm: {
                definitionCode: [],
                extraProp: {
                    code: ''
                },
                currentStatus: '',
                type: '',
                user: '', //用户账号
                userRole: [] //角色code
            },
            showStepInfo: false,
            showStepIndex: 0,
            currentRow: {}
        };
    },
    computed: {
        canCreate() {
            return this.$store.state.userInfo.roleCodeList.indexOf('WorkticketDirector') > -1;
        }
    },
    mounted() {
        this.$root.$on('workTicketRefresh', this.refresh);
    },
    methods: {
        init() {
            this.dataForm.currentStatus = '';
        },
        state() {
            this.init();
        },
        /*createFlow(row) {
            this.showHandel = true;
            this.flowPanel = false;
            row.type = row.code;
            this.$nextTick(() => {
                this.$refs.handel_win.create(row);
            });
        },*/
        createFlow(row) {
            row.type = row.code;
            this.$nextTick(() => {
                let guid = this.guid();
                sessionStorage.setItem(guid, JSON.stringify(row));
                this.$router.push({
                    path: '/twoTickets/workTicket/list/workTicketHandle',
                    query: {
                        id: guid,
                        handleType: 'create'
                    }
                });
            });
        },
        editFlow(item) {
            this.showHandel = true;
            this.flowPanel = false;
            this.$nextTick(() => {
                this.$refs.handel_win.edit(item);
            });
        },
        handleCurrentChange(val) {
            this.pageData.pageNumber = val;
            this.getData(this.type);
        },
        getData(type) {
            this.dataList = [];
            this.dataForm.type = type;
            this.initDefinitionCode();
            let page = {
                ...this.pageData,
                ...this.dataForm
            };
            this.$client.workflowInstanceQuery(page).then(res => {
                if (res.code === 0) {
                    let instanceList = res.data.items;
                    let recordList = [];
                    //查询实例
                    this.total = res.data.totalItemCount;
                    if (instanceList && instanceList.length) {
                        let ids = [];
                        instanceList.forEach(item => {
                            //当前node
                            let curNodeId = item.curNode ? item.curNode.id : '';
                            let records = item.records;
                            if (records && records.length) {
                                for (let i = records.length - 1; i >= 0; i--) {
                                    if (records[i].node.id === curNodeId) {
                                        //获取当前环节数据id
                                        ids.push(records[i].id);
                                        item.recordId = records[i].id;
                                        break;
                                    }
                                }
                            }
                        });
                        if (ids && ids.length) {
                            let param = {
                                ids,
                                pageSize: ids.length
                            };
                            this.$client.workflowInstanceQueryRecords(param).then(r => {
                                if (r.code === 0) {
                                    recordList = r.data.items;
                                    this.setDataList(instanceList, recordList);
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: 'error'
                                    });
                                }
                            });
                        }
                    }
                }
            });
        },
        //设置dataList
        setDataList(instanceList, recordList) {
            instanceList.forEach(item => {
                //item.records = item.records.sort((a, b) => b.endTime > a.endTime ? 1 : -1);
                if (item.records && item.rejectNodes) {
                    item.records.forEach(record => {
                        item.rejectNodes.forEach(recject => {
                            if (record.node.id === recject.id) {
                                recject.handler = record.handler;
                            }
                        });
                    });
                }
            });
            if (!instanceList && !instanceList.length) {
                return;
            }
            let map = new Map();
            if (recordList && recordList.length) {
                recordList.forEach(item => {
                    map.set(item.id, item.data);
                });
            }
            //设置处理人
            instanceList.forEach(item => {
                (item.rejectNodes || []).forEach(reject => {
                    item.records.forEach(record => {
                        if (record.node.id === reject.id) {
                            reject.handler = record.handler;
                        }
                    });
                });
            });
            instanceList.forEach(item => {
                let row = {
                    id: item.id, //实例id
                    instanceId: item.id, //实例id
                    definitionCode: item.definitionCode, //流程编码
                    nodeCode: item.nextNode ? item.nextNode.code : '', //下一环节code
                    curNodeCode: item.nextNode ? item.nextNode.code : '', //当前流程编码
                    nodeName: item.nextNode ? item.nextNode.name : '', //当前流程名称
                    user: this.dataForm.user,
                    roles: this.dataForm.userRole,
                    data: map.get(item.recordId) ? map.get(item.recordId) : {code: ''},
                    rejectNodes: item.rejectNodes
                };
                if (row.data) {
                    this.dataList.push(row);
                }
            });
        },
        search() {
            this.getData(this.type);
        },
        getRoleFlow() {
            //线路1
            let line1 = {
                code: 'elec_line_work_ticket_1',
                name: '线路第一种工作票'
            };
            //线路2
            let line2 = {
                code: 'elec_line_work_ticket_2',
                name: '线路第二种工作票'
            };
            //厂站1
            let factory1 = {
                code: 'elec_factory_work_ticket_1',
                name: '厂站第一种工作票'
            };
            //厂站2
            let factory2 = {
                code: 'elec_factory_work_ticket_2',
                name: '厂站第二种工作票'
            };
            //厂站3
            let factory3 = {
                code: 'elec_factory_work_ticket_3',
                name: '厂站第三种工作票'
            };
            this.instanceList.push(line1);
            this.instanceList.push(line2);
            this.instanceList.push(factory1);
            this.instanceList.push(factory2);
            this.instanceList.push(factory3);
        },
        refresh() {
            this.code = '';
            this.dataForm.key = '';
            this.dataForm.currentStatus = '';
            this.dataForm.extraProp.code = '';
            this.search();
        },
        handleCommand(v) {
            this.createFlow(v);
        },
        closeForm() {
            //恢复初始状态
            this.flowPanel = true;
            this.showHandel = false;
            this.showInfo = false;
            this.getData(this.type);
        },
        handleSelect(key) {
            this.type = key;
            this.getData(key);
        },
        /*openInfo(row) {
            this.showInfo = true;
            this.flowPanel = false;
            this.$nextTick(() => {
                this.$refs.info_win.init(row);
            });
        },*/
        openInfo(row) {
            row.isShowAll = true;
            this.$nextTick(() => {
                let guid = this.guid();
                sessionStorage.setItem(guid, JSON.stringify(row));
                this.$router.push({
                    path: '/twoTickets/workTicket/list/workTicketInfo',
                    query: {
                        id: guid,
                        handleType: 'create'
                    }
                });
            });
        },
        handelFlow(row) {
            this.$nextTick(() => {
                let guid = this.guid();
                sessionStorage.setItem(guid, JSON.stringify(row));
                this.$router.push({
                    path: '/twoTickets/workTicket/list/workTicketHandle',
                    query: {
                        id: guid,
                        handleType: 'handle'
                    }
                });
            });
        },
        /*handelFlow(row) {
            this.showHandel = true;
            this.flowPanel = false;
            this.$nextTick(() => {
                this.$refs.handel_win.handle(row);
            });
        },*/
        getInit() {
            this.getRoleFlow();
        },
        async initFormValue() {
            await this.getData(this.type);
            this.getInit();
        },
        closePanel() {
            this.showStepInfo = false;
        },
        clickStep(index) {
            this.showStepIndex = index;
            this.showStepInfo = true;
        },
        deleteFlow(id) {
            this.$confirm('确定删除操作吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let ids = [];
                ids.push(id);
                this.$client.workflowDelete(ids).then(res => {
                    if (res.code === 0) {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500
                        });
                        this.getData(this.type);
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                });
            });
        },
        initHandle() {
            let {act, obj} = this.$route.params;
            if (act) {
                switch (act) {
                    case 1:
                        //业扩变更
                        this.editFlow(obj);
                        break;
                }
            }
        },
        initUserInfo() {
            //设置用户
            this.dataForm.user = this.$store.state.userInfo.username;
            this.dataForm.userRole = this.$store.state.userRole;
        },
        initDefinitionCode() {
            this.dataForm.definitionCode = [];
            if (!this.code) {
                this.dataForm.definitionCode.push('elec_line_work_ticket_1');
                this.dataForm.definitionCode.push('elec_line_work_ticket_2');
                this.dataForm.definitionCode.push('elec_factory_work_ticket_1');
                this.dataForm.definitionCode.push('elec_factory_work_ticket_2');
                this.dataForm.definitionCode.push('elec_factory_work_ticket_3');
            } else {
                this.dataForm.definitionCode.push(this.code);
            }
        },
        guid() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }
    },
    created() {
        this.initUserInfo();
        this.initFormValue();
        this.initHandle();
    }
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/variables.less';

.grid-content {
    .el-select--mini,
    .el-input--mini {
        width: 150px;
        margin-right: 10px;
    }
}

.def-page-foot {
    .el-pagination {
        position: absolute;
        top: calc(50% + 10px);
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.load-workflow-step {
    background-color: @bg-conent;
    height: 100%;
    overflow: hidden scroll;
    // /deep/ .el-card{
    //     background-color: @bg-conent;
    // }
    /deep/ .card-pub-notice {
        background-color: @bg-conent;
        margin-top: 10px;

        .card-pub-notice {
            background-color: @bg-light;
            margin-top: 10px;

            .card-pub-notice {
                background-color: @bg-conent;
                margin-top: 10px;
            }
        }
    }

    /deep/ .submit-btn-group {
        text-align: center;
        margin-top: 60px;
        margin-bottom: 60px;
    }

    /deep/ .el-card__header {
        padding-left: 10px;
    }
}

.workflow-list {
    position: relative;
    height: calc(100% - 10px);

    &__action-btn {
        float: right;

        & + & {
            margin-left: 10px;
        }
    }

    &__notice-count {
        background-color: #ff7260;
        color: #fff;
        border-radius: 10px;
        font-size: 12px;
        padding: 0 5px;
        position: relative;
        top: -8px;
    }

    &__card-head-border {
        border-left: 3px solid @bg-btn-light;
        padding-left: 10px;
    }

    &__card-head-type {
        margin-left: 30px;
    }

    &__item-card {
        background-color: @bg-dark;

        & + & {
            margin-top: 20px;
        }
    }

    &__nav-form {
        padding: 10px;
        padding-bottom: 20px;
    }

    &__wrap {
        background-color: @bg-light;
        height: 100%;
        width: 100%;
    }

    &__content {
        height: calc(100% - 140px);
        overflow: auto;
        padding: 20px;
        box-sizing: border-box;
    }

    &__foot {
        height: 35px;
    }

    &__header {
        height: 105px;
        background-color: @bg-dark;
        border-bottom: 1px solid @bg-border;
    }

    &__navbar {
        border-bottom: 1px solid @bg-border;
        padding-top: 10px;

        /deep/ .el-menu {
            background-color: transparent;
        }

        /deep/ .el-menu.el-menu--horizontal {
            height: 30px;
            border: none;
            margin-right: 0;
            margin-bottom: 0;
        }

        /deep/ .el-menu--horizontal > .el-menu-item {
            height: 35px;
            line-height: 35px;
        }

        /deep/ .el-header {
            padding: 0;
        }

        /deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
        /deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
        /deep/ .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
            background-color: lighten(@bg-dark, 22%);
        }

        /deep/ .el-menu--horizontal > .el-menu-item.is-active {
            border-color: #ededfd;
        }
    }

    /deep/ .el-card__header {
        padding: 15px 20px;
    }

    /deep/ .el-card__body {
        padding: 15px 20px;
        font-size: 14px;
    }
}
</style>
