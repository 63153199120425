<template>
    <div>
        <!--信息展示组件-->
        <work-ticket-line v-if="showWorkTicketLineInfo" ref="ref_flow_work_ticket_line"
                          @closeForm="closeForm"></work-ticket-line>
        <work-factory v-if="showWorkFcatory" ref="ref_work_factory_info" @closeForm="closeForm"></work-factory>
    </div>
</template>

<script>
/**
 * 通用信息展示组件
 */
import WorkTicketLine from '../line/workTicketLine';
import WorkFactory from '../factory/workTicketFactory';
export default {
    components: {
        WorkFactory,
        WorkTicketLine,
    },
    data() {
        return {
            showWorkFcatory: false,
            currentData: {},
            showWorkTicketLineInfo: false,
            data: {}
        };
    },
    created() {
        this.init(JSON.parse(sessionStorage.getItem(this.$route.query.id)));
    },
    methods: {
        init(item) {
            this.currentData = item;
            switch (item.data.type) {
                case 'elec_line_work_ticket_1':
                case 'elec_line_work_ticket_2':
                    this.showWorkTicketLineInfo = true;
                    item.isInfo = true;
                    this.$nextTick(() => {
                        this.$refs.ref_flow_work_ticket_line.init(item);
                    });
                    break;
                case 'elec_factory_work_ticket_1':
                case 'elec_factory_work_ticket_2':
                case 'elec_factory_work_ticket_3':
                    this.showWorkFcatory = true;
                    item.isInfo = true;
                    this.$nextTick(() => {
                        this.$refs.ref_work_factory_info.init(item);
                    });
                    break;
                default:
                    break;
            }
        },
        closeForm(type) {
            //this.$emit('closeForm', type);
            this.$root.$emit('fe-framework:tab:close', this.$route.fullPath);
        },
    }
};
</script>
