<template>
    <div>
        <work-ticket-line
                v-if="workTicketLineVisible"
                ref="ref_workTicketLine"
                @closeForm="closeForm"></work-ticket-line>
        <work-ticket-factory
            v-if="workTicketFactoryVisible"
            ref="ref_workTicketFactory"
            @closeForm="closeForm"></work-ticket-factory>
    </div>
</template>

<script>
import WorkTicketLine from '../line/workTicketLine';
import WorkTicketFactory from '../factory/workTicketFactory';

export default {
    components: {
        WorkTicketLine,
        WorkTicketFactory,
    },
    data() {
        return {
            currentData: {},
            workTicketLineVisible: false,
            workTicketFactoryVisible: false,
        };
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            let handleType = this.$route.query.handleType;
            if (handleType && handleType === 'create') {
                this.create(JSON.parse(sessionStorage.getItem(this.$route.query.id)));
            }
            if (handleType && handleType === 'handle') {
                this.handle(JSON.parse(sessionStorage.getItem(this.$route.query.id)));
            }
        },
        create(item) {
            switch (item.type) {
                case 'elec_line_work_ticket_1':
                case 'elec_line_work_ticket_2':
                    this.workTicketLineVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_workTicketLine.init(item);
                    });
                    break;
                case 'elec_factory_work_ticket_1':
                case 'elec_factory_work_ticket_2':
                case 'elec_factory_work_ticket_3':
                    this.workTicketFactoryVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_workTicketFactory.init(item);
                    });
                    break;
                default:
                    break;
            }
        },
        edit(item) {
            this.currentData = item;
            switch (item.data.type) {
                case 'elec_line_work_ticket_1':
                case 'elec_line_work_ticket_2':
                    this.workTicketLineVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_workTicketLine.init(item);
                    });
                    break;
                case 'elec_factory_work_ticket_1':
                case 'elec_factory_work_ticket_2':
                case 'elec_factory_work_ticket_3':
                    this.workTicketFactoryVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_workTicketFactory.init(item);
                    });
                    break;
                default:
                    break;
            }
        },
        handle(item) {
            switch (item.data.type) {
                case 'elec_line_work_ticket_1':
                case 'elec_line_work_ticket_2':
                    this.workTicketLineVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_workTicketLine.init(item);
                    });
                    break;
                case 'elec_factory_work_ticket_1':
                case 'elec_factory_work_ticket_2':
                case 'elec_factory_work_ticket_3':
                    this.workTicketFactoryVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_workTicketFactory.init(item);
                    });
                    break;
                default:
                    break;
            }
        },
        info(item) {},
        closeForm(type) {
            // this.$emit('closeForm', type);
            this.$root.$emit('fe-framework:tab:close', this.$route.fullPath);
            this.$root.$emit('workTicketRefresh');
        },
        clickStep(index) {
            this.$emit('clickStep', index);
        }
    }
};
</script>
